import Vue from "vue";
import Vuex from "vuex";
import app from "./modules/app";
import user from "./modules/user";
import permissions from "./modules/permissions";
import settings from "./modules/settings";
import tagsView from "./modules/tagsView";
import getters from "./getters";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
    user,
    permissions,
    settings,
    tagsView
  },
  getters
});

export default store;
