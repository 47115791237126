import request from "@/utils/request";

export function getQiniuToken() {
  return request({
    url: "/api/common/upload/token",
    method: "get",
    params: {}
  });
}

/**
 * 获取验证码图片【ok】
 * @param key
 */
export function getVerifyCode(key) {
  return request({
    url: "/api/common/verify/code",
    method: "get",
    params: {
      img_key: key
    }
  });
}

export function sendSmsCode(phone) {
  return request({
    url: "/api/common/sms/code",
    method: "get",
    params: {
      phone: phone
    }
  });
}

/**
 * 上传文件 【OK】
 * @type {string}
 */
export const uploadImgApi =
  process.env.VUE_APP_BASE_API + "/api/common/uploadImg";

/**
 * 上传文档
 * @type {string}
 */
export const uploadDoc = process.env.VUE_APP_BASE_API + "/api/common/uploadDoc";
/**
 * 上传视频
 * @type {string}
 */
export const uploadVideo =
  process.env.VUE_APP_BASE_API + "/api/common/uploadVideo";

export function uploadImg(formData) {
  return request({
    url: "/api/common/uploadImg",
    method: "post",
    formData,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}
